import {
  ActionBarContext,
  LocalStorageService,
  addActionBarItem,
  getServerLocation,
} from "@vendure/admin-ui/core";
import { firstValueFrom } from "rxjs";

const downloadBlob = (blob: Blob, fileName: string) => {
  const blobUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.setAttribute("hidden", "true");
  a.href = blobUrl;
  a.download = fileName;
  a.setAttribute("target", "_blank");
  a.click();
};

const getHeaders = (context: ActionBarContext) => {
  const headers: Record<string, string> = {};
  const localStorageService = context.injector.get(LocalStorageService);

  const channelToken = localStorageService.get("activeChannelToken");

  if (channelToken) {
    headers["vendure-token"] = channelToken;
  }

  const authToken = localStorageService.get("authToken");

  if (authToken) {
    headers.authorization = `Bearer ${authToken}`;
  }

  return headers;
};

export default [
  addActionBarItem({
    id: "export-order",
    label: "Exportar",
    locationId: "order-detail",
    icon: "download",
    onClick: async (event, context) => {
      const serverPath = getServerLocation();

      const response = await fetch(
        `${serverPath}/order-export/${context.route.snapshot.params.id}`,
        {
          headers: getHeaders(context),
        }
      );

      const blob = await response.blob();
      const order = await firstValueFrom(
        context.entity$.pipe((order) => order)
      );

      downloadBlob(blob, `${order?.code}.zip`);
    },
  }),
];
